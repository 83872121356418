// Purpose: Contains a list of known particles, to be used with the ignore list functionality.
export const KnownParticles : Record<string, string> = {
    "wa": "は",
    "ga": "が",
    "no": "の",
    "wo": "を",
    "ni": "に",
    "de": "で",
    "to": "と",
    "te": "て",
    "kara": "から",
    "made": "まで",
    "yori": "より",
    "mo": "も",
    "ka": "か",
    "ya": "や",
    "zo": "ぞ",
    "ne": "ね",
    "sa": "さ",
    "yo": "よ",
    "na": "な",
    "e": "へ",
    "ba": "ば",
    "demo": "でも",
    "dake": "だけ",
    "shika": "しか",
    "nado": "など",
    "kedo": "けど",
    "keredo": "けれど",
    "keredomo": "けれども",
    "shi": "し",
    "tte": "って",
    "tari": "たり",
    "noni": "のに",
    "node": "ので",
    "ni_kansuru": "に関する",
    "ni_taisuru": "に対する",//に関して and に関する and treated differently. 
   
}